<template>
  <AutoComplete
      v-model="internalModel"
      :suggestions="filteredContacts"
      field="namePostcode"
      @complete="searchContact"
      @item-select="onItemSelect"
      @clear="onItemClear"
      :forceSelection="forceSelection"
      :minLength=3
      placeholder="Search contact by name & postcode..."
  >
    <template #item="{ item }">
      <div class="autocomplete-row">
        <div class="autocomplete-name" :title="item.name">
          {{ item.name }}
        </div>
        <div class="autocomplete-code">{{ item.postcode }}</div>
      </div>
    </template>
  </AutoComplete>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: function () {
        return "";
      }
    },
    is_customer: Boolean,
    is_supplier: Boolean,
    is_agent: Boolean,
    is_individual: Boolean,
    forceSelection: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    internalModel: {
      get: function () {
        return this.value && this.value.namePostcode ? this.value.namePostcode : this.value;
      },
      set: function (newValue) {
        let customValue = newValue;
        if (typeof newValue === 'string' && !this.isContactInList(newValue)) {
          const splitValue = newValue.split(' - ');
          const name = splitValue[0];
          const postcode = splitValue.length > 1 ? splitValue[1] : '';
          customValue = {contact_id: null, name: name, postcode: postcode, namePostcode: newValue};
        }
        this.$emit('input', customValue);
        this.$emit('contactSelected', customValue);
      }
    }
  },
  data: function () {
    return {
      filteredContacts: [],
    };
  },
  methods: {
    searchContact: function (event) {
      if (!event.query.trim()) return;

      try {
        const queryParams = this.buildQueryParams(event.query);
        const response = this.ContactService.searchContactsWithType(queryParams);
        const self = this;
        response.then(function (contacts) {
          const formattedResponse = contacts.map(function (item) {
            const postcode = item.address && item.address.postcode ? item.address.postcode : "";
            return Object.assign({}, item, {
              postcode: postcode,
              namePostcode: item.name + " - " + postcode
            });
          });
          self.filteredContacts = formattedResponse;
        }).catch(function (error) {
          console.error("Error fetching contacts:", error);
        });
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    },
    buildQueryParams: function (searchTerm) {
      return {
        searchTerm: searchTerm,
        is_customer: this.is_customer,
        is_supplier: this.is_supplier,
        is_agent: this.is_agent,
        is_individual: this.is_individual,
      };
    },
    isContactInList: function (namePostcode) {
      return this.filteredContacts.some(function (contact) {
        return contact.namePostcode === namePostcode;
      });
    },
    onItemSelect: function (event) {
      this.$emit('contactSelected', event.item);
    },
    onItemClear: function () {
      this.$emit('contactCleared');
    },
  }
};
</script>

<style>
.autocomplete-row {
  display: flex;
  justify-content: space-between;
}

.autocomplete-code,
.autocomplete-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-code {
  width: 25%;
}

.autocomplete-name {
  width: 75%;
}
</style>
